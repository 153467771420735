import React, { FunctionComponent } from 'react';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { DOMAIN } from '../../../utils/constants';

const Footer: FunctionComponent = () => (
  <Container fluid className={`${styles.container}`}>
    <Row className="gx-2 gx-md-4 gy-2">
      <Col xs={12} sm="auto">
        © 2022
        {' '}
        {DOMAIN}
      </Col>
      <Col xs={12} sm="auto">
        <Nav className={styles.nav}>
          <Nav.Item>
            <Nav.Link as={Link} to="/privacy/">Privacy</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/terms/">Terms</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col xs={12} sm="auto" className={styles.affiliate}>
        Affiliate disclosure: We may receive a small commission if you make a purchase after clicking on some of our links.
      </Col>
    </Row>
  </Container>
);

export default Footer;
