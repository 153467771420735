import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import NotFound from './pages/NotFound/NotFound';
import Privacy from './pages/Privacy/Privacy';
import ScrollToTop from './components/Utils/ScrollToTop/ScrollToTop';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import About from './pages/About/About';
import { useResizeWindow } from './utils/hooks';
import useAnalytics from './utils/hooks/useAnalytics';
import { IS_PRODUCTION } from './utils/constants';
import CanonicalUrl from './components/Utils/CanonicalUrl/CanonicalUrl';

function App() {
  useResizeWindow();
  useAnalytics();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | HouseSit Scanner"
        defaultTitle="Thousands of housesits at one place | HouseSit Scanner"
      >
        {!IS_PRODUCTION && <meta name="robots" content="noindex" />}
      </Helmet>
      <ScrollToTop />
      <CanonicalUrl />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="locations" element={<Locations />} /> */}
        <Route path="terms/" element={<Terms />} />
        <Route path="privacy/" element={<Privacy />} />
        <Route path="how-it-works/" element={<HowItWorks />} />
        <Route path="about/" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
