import React, { FunctionComponent } from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { AssignmentReadDto } from '../../../myApi';
import styles from './Card.module.css';
import Responsibilities from './Responsibilities';
import { platforms } from '../../../utils/platforms';

const Card : FunctionComponent<{ assignment: AssignmentReadDto }> = ({ assignment }) => {
  const platform = platforms.find((x) => x.id === assignment.platform);

  return (
    <Col>
      <a className={styles.container} href={assignment.url || undefined} target="_blank" rel="noreferrer">
        <div className={styles.image} style={{ backgroundImage: `url("${assignment.imageUrl}")` }} />
        <h3 className={styles.title}>{assignment.title}</h3>
        <div className={styles.dates}>
          {assignment.startDate && assignment.endDate
            ? (
              <>
                {moment(assignment.startDate).format('DD MMM YYYY')}
                {' '}
                -
                {' '}
                {moment(assignment.endDate).format('DD MMM YYYY')}
              </>
            )
            : 'Dates not filled in'}
        </div>
        <div className={styles.location}>
          {assignment.location}
        </div>
        <footer className={styles.footer}>
          <div>
            <Responsibilities assignment={assignment} />
          </div>
          {platform && platform.image && (
          <img
            className={styles.logo}
            alt={platform.name}
            src={platform.image}
            title={platform.name}
          />
          )}
        </footer>
      </a>
    </Col>
  );
};

export default Card;
