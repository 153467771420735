import React, {
  FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import Map, {
  LngLatBounds, LngLatBoundsLike, MapRef, Marker,
} from 'react-map-gl';
import { faMapMarker } from '@ondrejnepozitek/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './MapView.module.css';
import './Map.css';
import { GeoClusterDto } from '../../../myApi';
import { MapBounds } from '../../../utils/types';
import useIsMobile from '../../../utils/hooks/useIsMobile';

type Props = {
  clusters: GeoClusterDto[] | null;
  onMove: (bounds: LngLatBounds) => void;
  bounds: MapBounds;
};

function getMarkerText(number: number) : string {
  if (number < 1000) {
    return number.toString();
  }

  const roundedNumber = (number / 1000).toFixed(1);
  return `${roundedNumber}k`;
}

type CustomMarkerProps = {
  text: string;
  lat: number;
  lon: number;
  onClick: (lat: number, lon: number) => void;
};

const doNotUpdate = 'doNotUpdate';

const CustomMarker : FunctionComponent<CustomMarkerProps> = ({
  text, lat, lon, onClick,
}) => (
  <Marker
    longitude={lon}
    latitude={lat}
  >
    <div className={styles.marker} onClick={() => onClick(lat, lon)}>
      <FontAwesomeIcon className={styles.markerIcon} icon={faMapMarker as any} />
      <span className={styles.markerText}>
        {text}
      </span>
    </div>
  </Marker>
);

const initialState = {
  latitude: 25,
  longitude: 15,
  zoom: 0.5,
};

const MapView : FunctionComponent<Props> = ({ clusters, onMove, bounds }) => {
  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState(initialState);
  const isMobile = useIsMobile();

  const onMarkerClick = useCallback(({ longitude, latitude }) => {
    mapRef.current?.easeTo({
      center: [longitude, latitude],
      duration: 1000,
      zoom: viewState.zoom + 2,
    });
  }, [viewState]);

  const fitBounds = useCallback(() => {
    if (bounds === 'notTracked') {
      // Do nothing
    } else if (bounds === null) {
      setViewState(initialState);
    } else {
      mapRef.current?.fitBounds(bounds, undefined, { [doNotUpdate]: true });
    }
  }, [bounds]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds, bounds]);

  const handleMove = (newBounds: LngLatBounds) => {
    // if (bounds !== null && bounds !== 'notTracked') {
    //   const oldCenter = bounds.getCenter();
    //   const newCenter = newBounds.getCenter();
    //   const distance = oldCenter.distanceTo(newCenter);
    //
    //   if (distance < 1) {
    //     return;
    //   }
    // }

    onMove(newBounds);
  };

  return (
    <div className={styles.container}>
      <Map
        ref={mapRef}
        {...viewState}
        mapboxAccessToken="pk.eyJ1Ijoib25kcmFuIiwiYSI6ImNsMjZpb3I0bzA1emYza28xM29lNG02c2MifQ.KmCO7QPOf1EHbKnZyBJ_IQ"
        mapStyle="mapbox://styles/mapbox/streets-v9"
        onMove={(e) => {
          setViewState(e.viewState);
        }}
        onMoveEnd={(e) => {
          if ((e as any)[doNotUpdate] === true) {
            return;
          }

          const newBounds = e.target.getBounds();
          handleMove(newBounds);
        }}
        renderWorldCopies={false}
        onLoad={fitBounds}
        dragRotate={false}
        cooperativeGestures={isMobile}
        reuseMaps
      >
        {clusters && clusters.map((cluster) => (
          <CustomMarker
            key={`${cluster.latitude}-${cluster.longitude}-${cluster.count}`}
            text={getMarkerText(cluster.count!)}
            lat={cluster.latitude!}
            lon={cluster.longitude!}
            onClick={(lat: number, lon: number) => {
              onMarkerClick({ latitude: lat, longitude: lon });
            }}
          />
        ))}
      </Map>
    </div>
  );
};

export default MapView;
