/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Helmet } from 'react-helmet-async';
import styles from './HowItWorks.module.css';
import Layout from '../../components/Layout/Layout';
import TextContent from '../../components/TextContent/TextContent';
import { APP_NAME } from '../../utils/constants';

const HowItWorks: FunctionComponent = () => (
  <Layout>
    <Helmet>
      <title>How it works</title>
    </Helmet>
    <TextContent>
      <h1>How it works</h1>
      <p>{APP_NAME} is a platform that aggregates housesits from many different platforms, making it easier for you to find the sit that suits you the best. Right now, we scan 4 different housesitting platforms, but we plan to add more in the near future.</p>

      <p>The platforms that we support right now are:</p>
      <ul>
        <li><a href="https://www.trustedhousesitters.com/" target="_blank" rel="noreferrer">TrustedHousesitters</a> (international, with more than 5000 housesitting opportunities)</li>
        <li><a href="https://www.mindmyhouse.com/" target="_blank" rel="noreferrer">MindMyHouse</a> (international, with more than 300 housesitting opportunities)</li>
        <li><a href="https://mindahome.com.au/" target="_blank" rel="noreferrer">MindAHome Australia</a> (Australia, with more than 900 housesitting opportunities)</li>
        <li><a href="https://mindahome.co.uk/" target="_blank" rel="noreferrer">MindAHome UK</a> (United Kingdom, with more than 100 housesitting opportunities)</li>
      </ul>

      <h2>Unified search experience</h2>

      <p>Aside from having all the housesitting opportunities at a single place, we also aim to provide a unified search experience. Each housesitting platform provides a different set of filters that are mostly good enough for basic queries. However, they often lack some important filters that would make it easier for you to find your next sit.</p>

      <p>We want to provide a search experience that is simple to use but also robust enough for more advanced users.</p>

      <h2>Challenges</h2>

      <p>The main challenge of the {APP_NAME} platform lays in obtaining accurate data from different housesitting platforms. Unfortunately, some housesitting assignments only contain a textual description of the assignment, without any structured data. For example, a home owner might choose not to fill the <i>Pets</i> field of an assignment, and only mention in the text that you will be expected to take care of their two cats. In such cases, we might misclassify the assignment as not having any pets. We always work hard to minimize the number of such errors.</p>
    </TextContent>
  </Layout>
);

export default HowItWorks;
