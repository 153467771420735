import React, { FunctionComponent, useRef } from 'react';
import {
  Container, Row,
} from 'react-bootstrap';
import Card from '../Card/Card';
import { PaginatedAssignments } from '../../../utils/types';
import styles from './ListView.module.css';
import Pagination from '../Pagination/Pagination';
import { RESULTS_PER_PAGE } from '../../../utils/constants';

type Props = {
  assignments: PaginatedAssignments;
  onPageChanged: (page: number) => void;
  currentPage: number;
};

const ListView : FunctionComponent<Props> = ({ assignments, onPageChanged, currentPage }) => {
  const myRef = useRef(null);
  // @ts-ignore
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div>
      <div className={styles.header} ref={myRef}>
        There are currently
        {' '}
        {assignments.totalResults}
        {' '}
        sits in this area
      </div>

      <Row xs={1} md={2} xxl={3} className="g-4 gy-4">
        {assignments.data!.map((assignment) => (
          <Card key={assignment.id} assignment={assignment} />
        ))}
      </Row>

      <Pagination
        currentPage={currentPage}
        totalPages={assignments.totalPages!}
        onChange={(number: number) => {
          onPageChanged(number);
          executeScroll();
        }}
      />

      <div className={styles.stats}>
        Showing
        {' '}
        {(currentPage - 1) * RESULTS_PER_PAGE + 1}
        {' - '}
        {currentPage * RESULTS_PER_PAGE}
        {' of '}
        {assignments.totalResults!}
        {' '}
        places to sit in this area
      </div>
    </div>
  );
};

export default ListView;
