import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import qs from 'query-string';
import { LngLatBounds } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { AssignmentsQuery, MapBounds, PaginatedAssignments } from './types';
import { Api, GeoClusterDto, QueryParamsType } from '../myApi';
import {
  API_URL, IS_PRE_RENDERING, QUERY_STRING_OPTIONS, RESULTS_PER_PAGE,
} from './constants';
import { AssignmentFilter, LocationFilter } from '../components/Assignments/Filter/filterTypes';

type UseAssignmentsReturn = {
  assignments: PaginatedAssignments | null;
  currentPage: number;
  handleChangePage: (page: number) => void;
  handleFilterChange: (filter: AssignmentFilter) => void;
  state: State;
  handleMapChange: (bounds: LngLatBounds) => void;
  geoClusters: GeoClusterDto[] | null;
  mapBounds: MapBounds;
};

type State = {
  currentPage: number,
  filter: AssignmentFilter,
};

const initialState : State = {
  currentPage: 1,
  filter: {},
};

function getInitialState(searchParams: URLSearchParams) : State {
  const state = { ...initialState };
  const query : AssignmentsQuery = qs.parse(searchParams.toString(), QUERY_STRING_OPTIONS);

  if (query.page) {
    state.currentPage = parseInt(query.page, 10);
    delete query.page;
  }

  state.filter = {
    ...query,
    sWLat: query.sWLat ? parseFloat(query.sWLat) : undefined,
    sWLng: query.sWLng ? parseFloat(query.sWLng) : undefined,
    nELat: query.nELat ? parseFloat(query.nELat) : undefined,
    nELng: query.nELng ? parseFloat(query.nELng) : undefined,
  };

  return state;
}

function getMapBounds(filter: LocationFilter) : LngLatBounds | null {
  if (filter.nELat !== undefined && filter.sWLat !== undefined
    && filter.nELng !== undefined && filter.sWLng !== undefined) {
    return new mapboxgl.LngLatBounds([filter.sWLng, filter.sWLat,
      filter.nELng, filter.nELat]);
  }

  return null;
}

type AppState = 'initialLoad' | 'default' | 'updateUrl' | 'updateState';

function useAssignments() : UseAssignmentsReturn {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initialStateValueConst = getInitialState(searchParams);
  const [state, setState] = useState<State>(initialStateValueConst);
  const [assignments, setAssignments] = useState<PaginatedAssignments | null>(null);
  const [clusters, setClusters] = useState<GeoClusterDto[] | null>(null);
  const [mapBounds, setMapBounds] = useState<MapBounds>(null);
  const location = useLocation();
  const appState = useRef<AppState>('initialLoad');

  // Redirect to a new url when state changes
  useEffect(() => {
    if (appState.current === 'updateState' || appState.current === 'initialLoad') {
      appState.current = 'default';
      setMapBounds(getMapBounds(state.filter));
    } else {
      const lngLatPrecision = 4;
      const query : AssignmentsQuery = {
        resultsPerPage: RESULTS_PER_PAGE.toString(),
        page: state.currentPage === 1 ? undefined : state.currentPage.toString(),
        ...state.filter,
        sWLat: state.filter.sWLat?.toFixed(lngLatPrecision),
        sWLng: state.filter.sWLng?.toFixed(lngLatPrecision),
        nELat: state.filter.nELat?.toFixed(lngLatPrecision),
        nELng: state.filter.nELng?.toFixed(lngLatPrecision),
      };
      const queryString = qs.stringify(query, QUERY_STRING_OPTIONS);

      navigate({
        search: `?${queryString}`,
      }, {
        replace: false,
      });

      appState.current = 'updateUrl';
    }
  }, [navigate, state]);

  useEffect(() => {
    const fetchData = async () => {
      if (appState.current === 'updateUrl') {
        appState.current = 'default';
      } else {
        const stateFromSearchParams = getInitialState(searchParams);
        setState(stateFromSearchParams);
        appState.current = 'updateState';
      }

      if (location.search === '') {
        setMapBounds(null);
      }

      const api = new Api({
        baseUrl: API_URL,
      });

      const query : AssignmentsQuery = qs.parse(location.search, QUERY_STRING_OPTIONS);
      query.resultsPerPage = RESULTS_PER_PAGE.toString();

      // @ts-ignore
      api.toQueryString = (rawQuery?: QueryParamsType) => qs.stringify(rawQuery!, QUERY_STRING_OPTIONS);

      if (!IS_PRE_RENDERING) {
        const response = await api.api.assignmentList(query as any);
        setAssignments(response.data as PaginatedAssignments);

        const clustersResponse = await api.api.assignmentClustersList(query as any);
        setClusters(clustersResponse.data);
      }
    };
    fetchData().catch(console.error);
  }, [location]);

  const handleChangePage = useCallback((page: number) => {
    setState((prevState) => ({ ...prevState, currentPage: page }));
  }, []);

  const handleFilterChange = useCallback((filter: AssignmentFilter) => {
    let locationChanged = false;
    setState((prevState) => {
      locationChanged = prevState.filter.location !== filter.location;
      return ({ ...prevState, filter, currentPage: 1 });
    });

    if (locationChanged) {
      setMapBounds(getMapBounds(filter));
    }
  }, []);

  const handleMapChange = useCallback((bounds: LngLatBounds) => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        sWLat: bounds.getSouthWest().lat,
        sWLng: bounds.getSouthWest().lng,
        nELat: bounds.getNorthEast().lat,
        nELng: bounds.getNorthEast().lng,
        location: undefined,
      },
      currentPage: 1,
    }));
    setMapBounds(() => 'notTracked');
  }, []);

  return {
    assignments,
    currentPage: state.currentPage,
    handleChangePage,
    handleFilterChange,
    state,
    handleMapChange,
    geoClusters: clusters,
    mapBounds,
  };
}

export default useAssignments;
