import { PlatformId } from '../myApi';

export type Platform = {
  id: PlatformId;
  name: string;
  image?: string;
};

export const platforms : Platform[] = [
  { id: 1, name: 'TrustedHousesitters', image: '/logos/trustedhousesitters.png' },
  { id: 2, name: 'MindMyHouse', image: '/logos/mindmyhouse.png' },
  { id: 3, name: 'MindAHomeAustralia', image: '/logos/mindahomeaustralia.png' },
  { id: 4, name: 'MindAHomeUK', image: '/logos/mindahomeuk.png' },
];
