import React, { FunctionComponent } from 'react';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import styles from './Home.module.css';
import Layout from '../../components/Layout/Layout';
import useAssignments from '../../utils/useAssignments';
import ListView from '../../components/Assignments/ListView/ListView';
import MapView from '../../components/Assignments/MapView/MapView';
import Filter from '../../components/Assignments/Filter/Filter';
import NewFilter from '../../components/Assignments/Filter/NewFilter';

const Home: FunctionComponent = () => {
  const {
    assignments,
    currentPage, handleChangePage,
    handleFilterChange, state,
    geoClusters, handleMapChange, mapBounds,
  } = useAssignments();

  return (
    <Layout>
      {/* <Filter onChange={handleFilterChange} values={state.filter} /> */}
      <NewFilter onChange={handleFilterChange} values={state.filter} />

      <Container fluid className="g-0">
        <Row style={{ position: 'relative' }} className="g-0 flex-column-reverse flex-lg-row">
          <Col className={`${styles.list} primary-padding`} xs={12} lg={7}>
            {assignments ? (
              <ListView
                assignments={assignments}
                onPageChanged={handleChangePage}
                currentPage={currentPage}
              />
            ) : (
              <div className={styles.spinner}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </Col>
          <Col className={styles.map} xs={12} lg={5}>
            <MapView
              clusters={geoClusters}
              onMove={handleMapChange}
              bounds={mapBounds}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
